import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Info from '@/components/Info';

const Success = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{ t('common:page-titles.success') }</title>
      </Helmet>
      <Info type="SUCCESS" />
    </>
  );
};

export default Success;
